<template>
  <div class="lensAssessment">
    <h3>主诉</h3>
    <div STYLE="padding-bottom: 20px" class="zs">
      <p>配镜时间</p>
      <el-select class="sr" v-model="postData.CL_Recheck_Say_WearedTime" filterable allow-create placeholder="请选择">
        <el-option
            v-for="item in time"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <p>佩戴时长</p>
      <el-select class="sr" v-model="postData.CL_Recheck_Say_WearDuration" filterable allow-create placeholder="请选择">
        <el-option
            v-for="item in pdTime"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <p>是否连续佩戴</p>
      <el-select class="sr" v-model="postData.CL_Recheck_Say_WearContinuously" filterable allow-create placeholder="请选择">
        <el-option
            v-for="item in lxpd"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="zs">
      <p>清晰度</p>
      <el-input class="sr" @change="zh('CL_Recheck_Say_Clear')" v-model="postData.CL_Recheck_Say_Clear" placeholder="请输入内容"></el-input>
      <p>舒适度</p>
      <el-input class="sr" @change="zh('CL_Recheck_Say_Comfort')"  v-model="postData.CL_Recheck_Say_Comfort" placeholder="请输入内容"></el-input>
      <p>调整镜片后</p>
      <el-select class="sr" v-model="postData.CL_Recheck_Say_Adjust" filterable allow-create placeholder="请选择">
        <el-option
            v-for="item in tzjph"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div>
      <el-checkbox-group v-model="diagnosis" class="dx">
        <el-checkbox class="xxbos"  label="1">正常</el-checkbox>
        <el-checkbox class="xxbos"  label="2">重影</el-checkbox>
        <el-checkbox class="xxbos"  label="3">异物感</el-checkbox>
        <el-checkbox class="xxbos"  label="4">视力波动</el-checkbox>
        <el-checkbox class="xxbos"  label="5">镜片难摘</el-checkbox>
        <el-checkbox class="xxbos" label="6">虚影</el-checkbox>
        <el-checkbox class="xxbos" label="7">眼红</el-checkbox>
        <el-checkbox class="xxbos" label="8">眼痛</el-checkbox>
        <el-checkbox class="xxbos" label="9">分泌物增多</el-checkbox>
        <el-checkbox class="xxbos" label="10">视力不佳</el-checkbox>
        <el-checkbox class="xxbos" label="11">其他</el-checkbox>
      </el-checkbox-group>
    </div>

    <h3>备注</h3>
    <div style="text-align: left;">
      <el-input
          class="bz"
          v-model="postData.CL_Recheck_Say_remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
      >
      </el-input>
    </div>

    <h3>镜片检查</h3>
    <div v-if="!isjq" class="srA">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>使用时间</li>
          <li>沉淀物类型</li>
          <li>沉淀程度</li>
          <li>划伤位置</li>
          <li>划伤程度</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('CL_Recheck_Sur_timeOD')" v-model="postData.CL_Recheck_Sur_timeOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_Sur_precipitateOD')" v-model="postData.CL_Recheck_Sur_precipitateOD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_Sur_level1OD')" v-model="postData.CL_Recheck_Sur_level1OD" placeholder="请输入内容"></el-input></li>
          <li class="ys"><IMGTY></IMGTY></li>
          <li><el-input @change="zh('CL_Recheck_Sur_level2OD')" v-model="postData.CL_Recheck_Sur_level2OD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('CL_Recheck_Sur_timeOS')" v-model="postData.CL_Recheck_Sur_timeOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_Sur_precipitateOS')" v-model="postData.CL_Recheck_Sur_precipitateOS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_Sur_level1OS')" v-model="postData.CL_Recheck_Sur_level1OS" placeholder="请输入内容"></el-input></li>
          <li class="ys"><IMGTY></IMGTY></li>
          <li><el-input @change="zh('CL_Recheck_Sur_level2OS')" v-model="postData.CL_Recheck_Sur_level2OS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>


    <div v-if="isjq" class="srA">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>使用时间</li>
          <li>划伤位置</li>
          <li>划伤程度</li>
          <li>镜片外表面</li>
<!--          <li>镜片内表面</li>-->
<!--          <li>镜片边缘</li>-->
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_timeOD" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in time1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><IMGTY :type="'RGPScratchPositionOD'"></IMGTY></li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_level2OD" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in hscd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_outsideSurfaceOD" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpwbm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
<!--          <li class="ys">-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_insideSurfaceOD" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpwbm"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_marginOD" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpby"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_timeOS" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in time1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><IMGTY :type="'RGPScratchPositionOS'"></IMGTY></li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_level2OS" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in hscd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_outsideSurfaceOS" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpwbm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
<!--          <li class="ys">-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_insideSurfaceOS" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpwbm"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_marginOS" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpby"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
    <div v-if="isjq" class="srA"  style="padding-top: 10px;">
      <div>
        <ul class="content-sr">
          <li></li>
<!--          <li>使用时间</li>-->
<!--          <li>划伤程度</li>-->
<!--          <li>镜片外表面</li>-->
          <li>镜片内表面</li>
          <li>镜片边缘</li>
          <li>拍照</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_timeOD" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in time1"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_level2OD" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in hscd"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_outsideSurfaceOD" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpwbm"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
          <li class="ys">
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_insideSurfaceOD" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpwbm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_marginOD" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpby"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><IMGTY :type="'RGPCheckPhotoOD'"></IMGTY></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_timeOS" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in time1"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_level2OS" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in hscd"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
<!--          <li>-->
<!--            <el-select class="sr" v-model="postData.CL_Recheck_Sur_outsideSurfaceOS" filterable allow-create placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in jpwbm"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </li>-->
          <li class="ys">
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_insideSurfaceOS" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpwbm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select class="sr" v-model="postData.CL_Recheck_Sur_marginOS" filterable allow-create placeholder="请选择">
              <el-option
                  v-for="item in jpby"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li><IMGTY :type="'RGPCheckPhotoOS'"></IMGTY></li>
        </ul>
      </div>
    </div>


    <h3>镜片参数</h3>
    <div class="srA">
      <div>
        <ul class="content-sr">
          <li></li>
          <li>AC</li>
          <li v-if="!isjq">RP</li>
          <li v-if="!isjq">DIA</li>
          <li v-if="isjq">降幅</li>
          <li v-if="isjq">颜色</li>
          <li>备注</li>
          <li>镜片特殊定制</li>
        </ul>
        <ul class="content-sr">
          <li>OD</li>
          <li><el-input @change="zh('CL_Recheck_para_AC_OD')" v-model="postData.CL_Recheck_para_AC_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_RP_OD')" v-model="postData.CL_Recheck_para_RP_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_DIA_OD')" v-model="postData.CL_Recheck_para_DIA_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_remark_OD')" v-model="postData.CL_Recheck_para_remark_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_remark_OD')" v-model="postData.CL_Recheck_para_special_OD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li>OS</li>
          <li><el-input @change="zh('CL_Recheck_para_AC_OS')" v-model="postData.CL_Recheck_para_AC_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_RP_OS')" v-model="postData.CL_Recheck_para_RP_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_DIA_OS')" v-model="postData.CL_Recheck_para_DIA_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_remark_OS')" v-model="postData.CL_Recheck_para_remark_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @change="zh('CL_Recheck_para_remark_OD')" v-model="postData.CL_Recheck_para_special_OS" placeholder="请输入内容"></el-input></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lensAssessment",
  data() {
    return {
      value: '',
      time: [
        {
          value: '初次',
          label: '初次'
        },
        {
          value: '1周',
          label: '1周'
        },
        {
          value: '2周',
          label: '2周'
        },
        {
          value: '1个月',
          label: '1个月'
        },
        {
          value: '2个月',
          label: '2个月'
        },
        {
          value: '3个月',
          label: '3个月'
        },
        {
          value: '4个月',
          label: '4个月'
        },
        {
          value: '5个月',
          label: '5个月'
        },
        {
          value: '6个月',
          label: '6个月'
        },
        {
          value: '7个月',
          label: '7个月'
        },
        {
          value: '8个月',
          label: '8个月'
        },
        {
          value: '9个月',
          label: '9个月'
        },
        {
          value: '10个月',
          label: '10个月'
        },
        {
          value: '11个月',
          label: '11个月'
        },
        {
          value: '12个月',
          label: '12个月'
        },
        {
          value: '>1年',
          label: '>1年'
        }
      ],
      time1: [
        {
          value: '取镜',
          label: '取镜'
        },
        {
          value: '1周',
          label: '1周'
        },
        {
          value: '2周',
          label: '2周'
        },
        {
          value: '1个月',
          label: '1个月'
        },
        {
          value: '2个月',
          label: '2个月'
        },
        {
          value: '3个月',
          label: '3个月'
        },
        {
          value: '4个月',
          label: '4个月'
        },
        {
          value: '5个月',
          label: '5个月'
        },
        {
          value: '6个月',
          label: '6个月'
        },
        {
          value: '7个月',
          label: '7个月'
        },
        {
          value: '8个月',
          label: '8个月'
        },
        {
          value: '9个月',
          label: '9个月'
        },
        {
          value: '10个月',
          label: '10个月'
        },
        {
          value: '11个月',
          label: '11个月'
        },
        {
          value: '12个月',
          label: '12个月'
        },
        {
          value: '>1年',
          label: '>1年'
        }
      ],
      hscd: [
        {
          value: '轻',
          label: '轻'
        },
        {
          value: '中',
          label: '中'
        },
        {
          value: '重',
          label: '重'
        },
        {
          value: '',
          label: ''
        }
      ],
      jpwbm: [
        {
          value: '良好',
          label: '良好'
        },
        {
          value: '小划痕',
          label: '小划痕'
        },
        {
          value: '大划痕',
          label: '大划痕'
        },
        {
          value: '少量油脂蛋白',
          label: '少量油脂蛋白'
        },
        {
          value: '大量油脂蛋白',
          label: '大量油脂蛋白'
        },
        {
          value: '',
          label: ''
        }
      ],
      jpby: [
        {
          value: '良好',
          label: '良好'
        },
        {
          value: '缺损',
          label: '缺损'
        },
        {
          value: '',
          label: ''
        }
      ],

      pdTime: [
        {
          value: '6个小时',
          label: '6个小时'
        },
        {
          value: '7个小时',
          label: '7个小时'
        },
        {
          value: '8个小时',
          label: '8个小时'
        },
        {
          value: '9个小时',
          label: '9个小时'
        },
        {
          value: '10个小时',
          label: '10个小时'
        },
        {
          value: '11个小时',
          label: '11个小时'
        },
        {
          value: '12个小时',
          label: '12个小时'
        }
      ],
      lxpd: [
        {
          value: '是',
          label: '是'
        },
        {
          value: '否',
          label: '否'
        },
        {
          value: '',
          label: ''
        },
      ],
      postData: {
        CL_Recheck_Say_WearedTime: "",
        CL_Recheck_Say_WearDuration: "",
        CL_Recheck_Say_WearContinuously: "",
        CL_Recheck_Say_Clear: "",
        CL_Recheck_Say_Comfort: "",
        CL_Recheck_Say_remark: "",
        CL_Recheck_Sur_timeOD: "",
        CL_Recheck_Sur_precipitateOD: "",
        CL_Recheck_Sur_level1OD: "",
        CL_Recheck_Sur_level2OD: "",
        CL_Recheck_Sur_timeOS: "",
        CL_Recheck_Sur_precipitateOS: "",
        CL_Recheck_Sur_level1OS: "",
        CL_Recheck_Sur_level2OS: "",
        CL_Recheck_para_AC_OD: "",
        CL_Recheck_para_RP_OD: "",
        CL_Recheck_para_DIA_OD: "",
        CL_Recheck_para_remark_OD: "",
        CL_Recheck_para_AC_OS: "",
        CL_Recheck_para_RP_OS: "",
        CL_Recheck_para_DIA_OS: "",
        CL_Recheck_para_remark_OS: "",
        CL_Recheck_Sur_problem: '',
        CL_Recheck_Say_Adjust: '',
        CL_Recheck_para_special_OD: '',
        CL_Recheck_para_special_OS: '',
        CL_Recheck_Sur_outsideSurfaceOD:"",
        CL_Recheck_Sur_outsideSurfaceOS: '',
        CL_Recheck_Sur_insideSurfaceOD:'',
        CL_Recheck_Sur_insideSurfaceOS: '',
        CL_Recheck_Sur_marginOD: '',
        CL_Recheck_Sur_marginOS: ''
      },
      diagnosis: [],
      tzjph: [
        {
          value: '1周',
          label: '1周'
        },
        {
          value: '2周',
          label: '2周'
        },
        {
          value: '3周',
          label: '3周'
        },
        {
          value: '1月',
          label: '1月'
        },
        {
          value: '',
          label: ''
        },
      ]
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('corneaContact/upCorneaReview', n)
      },
      deep: true
    },
    diagnosis: {
      handler(n, o) {
        // console.log(n)
        let arr = [0,0,0,0,0,0,0,0,0,0,0]
        for (let i = 0; i < n.length; i++) {
          arr[n[i]-1] = 1
        }
        this.postData.CL_Recheck_Sur_problem = arr.join(',')
        // console.log(this.postData.CL_Recheck_Sur_problem)
        this.$store.commit('corneaContact/upCorneaReview', this.postData)
      },
      deep: true
    },
    "$store.state.corneaContact.isfcshowdata": {
      deep: true,
      handler(n, o) {
        if (!n) {return false}
        // this.postData = this.$store.state.corneaContact.corneaReview
        for (let key in this.postData) {
          this.postData[key] = this.$store.state.corneaContact.corneaReview[key]
        }
        if (this.postData.CL_Recheck_Sur_problem) {
          let  arr = [...this.postData.CL_Recheck_Sur_problem.split(',')], item = []
          for (let i = 0; i < arr.length; i++) {
            if (Number(arr[i])) {
              item.push((i+1).toString())
            }
          }
          this.diagnosis = [...item]
          console.log(this.postData)
        }
      }
    }
  },
  computed: {
    isjq() {
      return this.$store.state.users.user.Company.CompanyId === '42fd1c61-721c-11ed-bba1-7cd30adbe42c'
    }
  },
  created() {
    // this.postData = this.$store.state.corneaContact.corneaReview
    // console.log(this.postData)

  },
  methods: {
    zh(key, type=0) {
      let str = this.postData[key]
      if (str !== '' && Number(str) > 0) {
        if (type) {
          this.postData[key] = "+" + str
        }else {
          this.postData[key] = str.toString()
        }
      }else {
        this.postData[key] = str !== ''?str.toString():''
      }
    }
  },
  components: {
    IMGTY: () => import('../../jqDIY/cans')
  }
}
</script>

<style scoped lang="scss">
  h3 {
    padding: .3rem 0;
    text-align: left;
  }
  .zs {
    display: flex;
    align-items: center;
    justify-content: start;
    p {width: 6em;}
    .sr {width: 8vw; margin-right: .3rem; margin-left: .05rem;}
  }
  .bz {
    width: 70vw;
  }
  .mar-20 {margin-top: 30px}
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      .ys {color: #40a7f6;}
      li {
        width: 8vw;
        margin-right: .4rem;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3vw;
        text-align: left;
        margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: .5rem;
      margin-top: 32px;
    }
  }
  .dx {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
</style>
